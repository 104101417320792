import {
    Icon,
    OutlinedCard,
    Text,
    Column,
    OverlappingImages,
    JLP_LOGO,
    SANCTUM_LOGO,
    ORCA_LOGO,
    METEORA_LOGO
} from "@bridgesplit/ui";
import { AppDialog, useAppDialog } from "app/utils";
import { MarketExpanded, useLoopsExpanded, useMarkets } from "@bridgesplit/abf-react";
import { findMaxElement, formatPercent } from "@bridgesplit/utils";
import { COPY } from "app/constants";

import { AppButton } from "../common";
import { AccessStat } from "./common";

const ALLOWED_MARKETS = [
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    "2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo"
];
export default function LoopscaleBetaCard({ action }: { action: string | null }) {
    const { open } = useAppDialog();

    return (
        <OutlinedCard spacing={4} padding={3} sx={{ textAlign: "center", alignItems: "center" }}>
            <Text svgColor="secondary">
                <Icon type="logo" />
            </Text>
            <Column alignItems={"center"} spacing={1}>
                <Text fontWeight="normal" variant="h3">
                    Join Prime
                </Text>
                <Text variant="body1" color="caption">
                    The most advanced secured lending platform in the world.
                </Text>
            </Column>
            <Column sx={{ width: "100%" }} alignItems={"center"} spacing={1}>
                <Text variant="body2" color="caption">
                    {`Connect your wallet or email to ${action ?? "continue"}`}
                </Text>
                <AppButton
                    overrideAccess
                    onClick={() => open(AppDialog.Connect, undefined)}
                    color="secondary"
                    isTransaction={false}
                >
                    <Icon type="connect-wallet" />
                    {COPY.CONNECT_PROMPT}
                </AppButton>
            </Column>
        </OutlinedCard>
    );
}

function useNeedBetaAccessStats(): AccessStat[] {
    const { data } = useLoopsExpanded({});
    const { data: markets } = useMarkets();
    const custodians = [ORCA_LOGO, METEORA_LOGO, SANCTUM_LOGO, JLP_LOGO];

    const filteredMarketStats = markets?.filter((m) =>
        ALLOWED_MARKETS.some((allowedMarket) => allowedMarket === m.metadata.assetMint)
    );

    const lowestApyMarket = findLowestApyMarket(filteredMarketStats);

    const apy = lowestApyMarket ? lowestApyMarket : undefined;

    const loopWithMaxLeveragedApy = findMaxElement(data, (item) => item.loopVault.maxLeveragedApyPct);
    const betaStats: AccessStat[] = [
        {
            value: (
                <Text color="success">{formatPercent(loopWithMaxLeveragedApy?.loopVault.maxLeveragedApyPct)} APY</Text>
            ),
            caption: "Earn up to"
        },
        {
            value: <Text color="body">{`${formatPercent(apy)} APY`}</Text>,
            caption: "Borrow at"
        },
        {
            value: <StackedCustodianImages featuredCustodians={custodians} />,
            caption: "Leverage"
        }
    ];

    return betaStats;
}

function StackedCustodianImages({ featuredCustodians }: { featuredCustodians: string[] | undefined }) {
    return <OverlappingImages size="20px" images={featuredCustodians} maxLength={4} />;
}

function findLowestApyMarket(marketStats: MarketExpanded[] | undefined) {
    if (!marketStats) return undefined;
    return Math.min(...marketStats.flatMap((market) => market.strategyStats.durationToMinApy.map((item) => item.apy)));
}
